export const DEFAULT_TRUE = 1;
export const DEFAULT_FALSE = 0;
export const DEFAULT_DIFFERENCE = 5;
export const DEFAULT_PERPAGE = 5;
export const MALE_GENDER = 1;
export const FEMALE_GENDER = 2;
export const DEFAULT_VIDEO_VALUE = 104550400;
export const DEFAULT_VIDEO_TIME = 15;
export const CAR_OPTIONS = [
  { name: "あり" },
  { name: "なし" },
  { name: "レンタカー" }
];

export const LIMIT_USER_SUGGEST = 3;
export const TYPE_USER_SUGGEST_1 = 1;
export const TYPE_USER_SUGGEST_2 = 2;
export const MAX_IMAGE_SIZE = 3145728;
export const ALLOW_FILE_TYPE_IMAGE = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp"
];
